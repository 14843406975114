import React, { PureComponent } from 'react';
import brightcovePlayerLoader from '@brightcove/player-loader';

const _loadPlayer = videoId => {
  brightcovePlayerLoader({
    refNode: document.querySelector('#page-video'),
    accountId: '4794656764001',
    videoId: videoId,
    playerId: 'SsPCgzCt7',

    options: {
      fluid: true,
    },
  })
    .then(success => {
      // console.log(success);
    })
    .catch(err => {
      // console.log(err);
    });
};

class BrandVideo extends PureComponent {
  componentDidMount = () => {
    const { videoId } = this.props;
    _loadPlayer(videoId);
  };

  render = () => (
    <section className="brand-video">
      <h5 className="text-center">See For Yourself</h5>
      <div id="page-video" />
    </section>
  );
}

export default BrandVideo;
