import React from 'react';
import { PureComponent } from 'react';
import classNames from 'classnames';
// import brightcovePlayerLoader from '@brightcove/player-loader';

class BrandBanner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { player: null };
  }

  componentDidMount() {
    // console.log(`Loading YT Player with ${this.props.videoId}`);
    // brightcovePlayerLoader({
    //   refNode: document.querySelector('#hero-player'),
    //   accountId: '4794656764001',
    //   videoId: this.props.videoId,
    //   playerId: 'SsPCgzCt7',
    //   embedOptions: {
    //     responsive: true,
    //   },
    //   options: {
    //     autoplay: true,
    //     muted: true,
    //     controls: false,
    //   },
    // }).then(success => {
    //   success.ref.play();
    // });
  }

  render() {
    return (
      <section className={classNames('brand-videobg', this.props.extraClasses)}>
        <video autoPlay={true} playsInline={true} muted={true} loop={true}>
          <source src={this.props.html5videosrc} />
        </video>
        <div className="brand-copy">{this.props.children}</div>
      </section>
    );
  }
}

export default BrandBanner;
